<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Error :errors="errors"/>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('read')">
                    <Filter @submit="getDataTable()" ref="filter"/>
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)" :resizableColumns="true"  v-if="permission.includes('read')">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" v-if="permission.includes('download')"/>
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" v-if="permission.includes('download')"/>
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Search..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="#" :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="day_" header="Day">
                        <template #body="slotProps">
                            {{slotProps.data.day_}}
                        </template>
                    </Column>
                    <Column field="date_" header="Date">
                        <template #body="slotProps">
                            {{slotProps.data.date_}}
                        </template>
                    </Column>
                    <Column field="db_code" header="Distributor Code">
                        <template #body="slotProps">
                            {{slotProps.data.db_code}}
                        </template>
                    </Column>
                    <Column field="db_name" header="Distributor Name">
                        <template #body="slotProps">
                            {{slotProps.data.db_name}}
                        </template>
                    </Column>
                    <Column field="territory_name" header="Territory Name">
                        <template #body="slotProps">
                            {{slotProps.data.territory_name}}
                        </template>
                    </Column>
                    <Column field="city_name" header="City Name">
                        <template #body="slotProps">
                            {{slotProps.data.city_name}}
                        </template>
                    </Column>
                    <Column field="outlet_code" header="Outlet Code">
                        <template #body="slotProps">
                            {{slotProps.data.outlet_code}}
                        </template>
                    </Column>
                    <Column field="outlet_name" header="Outlet Name">
                        <template #body="slotProps">
                            {{slotProps.data.outlet_name}}
                        </template>
                    </Column>
                    <Column field="user_code" header="User Code">
                        <template #body="slotProps">
                            {{slotProps.data.user_code}}
                        </template>
                    </Column>
                    <Column field="name" header="User Name">
                        <template #body="slotProps">
                            {{slotProps.data.name}}
                        </template>
                    </Column>
                    <Column field="product_name" header="Product Description">
                        <template #body="slotProps">
                            {{slotProps.data.product_name}}
                        </template>
                    </Column>
                    <Column field="uom" header="UOM">
                        <template #body="slotProps">
                            {{slotProps.data.uom}}
                        </template>
                    </Column>
                    <Column field="qty" header="QTY">
                        <template #body="slotProps">
                            {{slotProps.data.qty}}
                        </template>
                    </Column>
                    <Column field="rate" header="Rate">
                        <template #body="slotProps">
                            {{slotProps.data.rate}}
                        </template>
                    </Column>
                    <Column field="amount" header="Amount">
                        <template #body="slotProps">
                            {{slotProps.data.amount}}
                        </template>
                    </Column>
                    <Column field="amount_vat" header="Amount (VAT)">
                        <template #body="slotProps">
                            {{slotProps.data.amount_vat}}
                        </template>
                    </Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]" v-if="permission.includes('read')">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';
import Filter from '../../components/database/outlet-orders/Filter.vue';
import Error from '../../components/Error.vue';

export default {
    components: {
		'Filter': Filter,
		'Error': Error,
	},
    data() {
        return {
            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        }
    },
    computed:{
        ...mapGetters(['errors']),

		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/outlet-order',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "territory_id" : this.$refs.filter.filters.territory_id,
                    "city_id" : this.$refs.filter.filters.city_id,
                    "db_id" : this.$refs.filter.filters.db_id,
                    "start_date" : this.$refs.filter.period_start_label,
                    "end_date" : this.$refs.filter.period_end_label,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
              this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
                this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/outlet-order/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
                "territory_id" : this.$refs.filter.filters.territory_id,
                "city_id" : this.$refs.filter.filters.city_id,
                "db_id" : this.$refs.filter.filters.db_id,
                "start_date" : this.$refs.filter.period_start_label,
                "end_date" : this.$refs.filter.period_end_label,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Outlet Orders Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>
